
import React from "react";

import "./BlankPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginButton from "../components/Login";
import LogoutButton from "../components/Logout";
import Profile from "../components/Profile";

import HoldingIcon from "../components/HoldingIcon";



export function Home() {

  

  return (
    <div>
      <Header footAct={false}></Header>
      <div className="AppContainer">
        <div className="AppMobile">
          <HoldingIcon></HoldingIcon>
          <LoginButton></LoginButton>
          
          <LogoutButton></LogoutButton>
          <Profile></Profile>
        </div>

        <div className="AppPC"></div>
      </div>
      <Footer disable="false"></Footer>
    </div>
  );
}

export default Home;
