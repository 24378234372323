import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

const Profile = () => {
    const {user, isAuthenticated, isLoading, error} = useAuth0();

    console.log("here in profile loading  " + isLoading);

    if (error) {
      return <div>Oops... {error.message}</div>;
    }

    if( isLoading ){
        return <div>Loading......</div>
    }
    console.log("Authenticated " + isAuthenticated);
  return (
    isAuthenticated && ( 
      
    <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
    </div>
    )
  ) 
}

export default Profile