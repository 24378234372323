import React from "react";
import logo from "../assets/aztech_logo.svg";
import "./HoldingIcon.css";




const HoldingIcon = () => {
  return (
    <div className="App-content">
      <p className="App-text">Turning things around</p>
      <img src={logo} className="App-logo" alt="logo"></img>
      <p className="App-text-punchline">with software!</p>
    </div>
  );
};

export default HoldingIcon;
