/* import logo from "../../src/assets/aztech_logo.svg"; */
import React from 'react'

import "./BlankPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactUs from '../components/ContactUs';


 export function Contact () {
    return (
      <div>
        <Header footAct="true"></Header>
        <div className="AppContainer">
          <div className="AppMobile">            
            <div className="App-content">
              <ContactUs></ContactUs>
            </div> 
          </div>

          <div className="AppPC"></div>
        </div>
        <Footer disable="true"></Footer>
      </div>      
    );
  }
 

export default Contact