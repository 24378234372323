import React from 'react'
import { useState } from 'react';
import { Link } from "react-router-dom"


const FooterLink = (props) => {
    const [mouseHover, setMouseHover] = useState(false);

    /*
        handle mouse events
    */
  const handleMouseEnter = () => {
   
    setMouseHover(true);    
  };

  const handleMouseExit = () => {
   
    setMouseHover(false);
  };

  const linkStyle = {
    textDecoration: 'none',
    color: mouseHover ? 'var(--logo-yellow)' : 'var(--white)', 
   
    transform: mouseHover ? 'scale(1.2)' : null,
  };

  const linkStyleDisable = {
    pointerEvents: 'none',
    color: 'var(--white-alpha-10)',
    textDecoration: 'none',
  };

  const lnkStyle = props.disable === "true" ? linkStyleDisable : linkStyle;


  return (
    <Link 
        to= {props.page}
        style={lnkStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}>
        {props.linkName}
    </Link>
  )
}

export default FooterLink