import React from 'react'

import "./BlankPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivacyPolicy from '../components/PrivacyPolicy';

const noticeBackground = {
  
  padding: '10px',
  border: '2px solid var(--green-alpha50)',
  margin: '5px',
  marginTop: '8rem',
  fontFamily: 'Roboto',
  backgroundColor:  'var(--white-alpha-40)',
};

 export function PrivacyNotice () {
    return (
      <div>
        <Header footAct="true"></Header>
        <div className="AppContainer">
          <div className="AppMobile">            
            <div className="App-content">
              <PrivacyPolicy basicStyle={{...noticeBackground}}></PrivacyPolicy>
            </div> 
          </div>

          <div className="AppPC"></div>
        </div>
        <Footer disable="true"></Footer>
      </div>      
    );
  }
 

export default PrivacyNotice