import React from "react";
import "./Notices.css";

const ContactUs = () => {
    const pStyle = {
        marginTop: '15px',
        marginBottom: '15px',
    };

  return (
    <div className="page_bg">
      <h1 className="h1_Style">How to contact us</h1>
      <p style = {pStyle}>
        We welcome feedback and are happy to answer any questions you may have
        about this website, the service we offer and your data.
      </p>
      <p style = {pStyle}>
        Please send any questions, comments or requests for more information to
        our nominated representative and Data Protection, who can be contacted
        at DPO@arcs-and-sparks.tech.
      </p>
      <p style = {pStyle}>
        This privacy notice was last updated on 29th July 2022 (Version v1.11)
      </p>
      <p style = {pStyle}>Arcs and Sparks Technology Ltd.</p>
      <p style = {pStyle}>Registered Company number : ----------------</p>
      <p style = {pStyle}>UK VAT Number : -------------- </p>
    </div>
  );
};

export default ContactUs;
