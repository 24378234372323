import React from "react";
import "./Notices.css";

const CookiesPolicy = () => {
  

  return (
    <div className="page_bg">
      <h1 className="h1_Style">Cookies Policy</h1>

      <p>
        This website, <strong>www.arcs-and-sparks.tech </strong>
        <i>(the "Website")</i>, is owned and operated by
        <em className="comp_name"> Arcs and Sparks Technology Ltd.</em>
      </p>
      <p>
        Like most similar websites, this site uses cookies. On this page we
        explain more about cookies and how we use them. By using this website
        and agreeing to this policy, you consent to our use of cookies in
        accordance with the terms of this policy.
      </p>

      <h2 className="h2_Style">What are cookies?</h2>

      <p>
        Cookies are a small text files that are stored in your web browser that
        allows websites such as ours to distinguish you from other uses of our
        website. They help us to provide you with the very best experience when
        you visit our website and over time allow us to make improvements to our
        website and its content.
      </p>
      <p>
        You can set your browser to refuse all or some browser cookies, or to
        alert you when websites set or access cookies. If you disable or refuse
        cookies, please note that some parts of our website may become
        inaccessible or not function properly.
      </p>

      <p>We use Cookies for the following purposes:</p>

      <ul>
        <li>To enable certain functions</li>
        <li>To provide analytics</li>
        <li>To store your preferences</li>
      </ul>

      <p>Our website uses both session cookies and persistent cookies.</p>

      <p>
        A session cookie is used to identify a particular visit to our Website.
        These cookies expire after a short time, or when you close your web
        browser after using our Website. We use these cookies to identify you
        during a single browsing session, such as when you log into our Website.
      </p>

      <p>
        A persistent cookie will remain on your devices for a set period of time
        specified in the cookie. We use these cookies where we need to identify
        you over a longer period of time. For example, we would use a persistent
        cookie if you asked that we keep you signed in.
      </p>

      <h2 className="h2_Style">How do third parties use cookies on our Website ?</h2>

      <p>
        Third party companies like analytics companies and ad networks generally
        use cookies to collect user information on an anonymous basis. They may
        use that information to build a profile of your activities on our
        Website and other websites that you've visited.
      </p>

      <h2 className="h2_Style">What are your cookies options?</h2>

      <p>
        If you don't like the idea of cookies or certain types of cookies, you
        can change your browser's settings to delete cookies that have already
        been set and to not accept new cookies. To learn more about how to do
        this, visit the help pages of your browser.
      </p>

      <p>
        Please note, however, that if you delete cookies or do not accept them,
        you might not be able to use all of the features we offer, you may not
        be able to store your preferences, and some of our pages might not
        display properly.
      </p>

      <h2 className="h2_Style">Where can I find more information about cookies?</h2>
      <p>
        Probably the best place to find out more on cookies and their use is
        <strong> Google.</strong>
      </p>
    </div>
  );
};

export default CookiesPolicy;
