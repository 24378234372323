import React from 'react'
import "./Notices.css";


const ConditionOfUse = () => {
  return (
    <div className="page_bg">
        <h1 className="h1_Style">Conditions of Use</h1>

        <h2>Access right and users</h2>
        <p>Need to have a metric to limit access to either a certain number of users or a certain amount of data. Your agreement should:</p>
        <ul>
            <li>Detail that metric</li>
            <li>Define what a user is</li>
            <li>Establish penalties for abuse</li>
        </ul>
        <h2>Customer service and support</h2>
        <ul>
            <li>How you will provide support for your services</li>
            <li>Response time</li>
            <li>Any additional guarantees in terms of expected service</li>
        </ul>
        <h2>Data ownership</h2>
        <p>This vital component of a cloud service agreement establishes who gets the rights to data that is entered into a platform or service.</p>
        <h2>Data security</h2>
        <p>This section is particularly important in consumer markets, especially with laws like the GDPR and CCPA. The section should detail:</p>
        <ul>
            <li>Encryption responsibilities</li>
            <li>How often data will be backed up</li>
            <li>Protections offered</li>
            <li>Security of entered data</li>
            <li>What happens to data in the event of:</li>
            <ul>
                <li>A security breach</li>
                <li>Bankruptcy</li>
                <li>Termination of service use</li>
            </ul>
            <li>Where data is stored.</li>
        </ul>
        <h2>License scope</h2>
        <p>You should define and limit the rights that you transfer to subscribers.</p>
        <h2>Limitation of liability</h2>
        <p>You should also make any damages available clear, and you can cap contractual liability.</p>
        <h2>Master agreement (language)</h2>
        <p>Most agreements will include language that makes this document the master document for all services you offer so you do not have to negotiate or sign a completely new contract every time a customer wants to renegotiate or change the services provided.</p>
        <h2>Performance objectives</h2>
        <p>Detail what end users should expect from your service. This should include:</p>
        <ul>
            <li>Guarantees</li>
            <li>Relevant results</li>
            <li>What your service does not promise</li>
        </ul>
        <h2>Pricing</h2>
        <p>Pricing and when your company has the right to charge detailed costs should be established in the agreement. As SaaS agreements typically use a subscription model, you will usually get payment in one of the following ways:</p>
        <ul>
            <li>Monthly</li>
            <li>Quarterly</li>
            <li>Yearly</li>
        </ul>
        <h2>Rights to a physical copy</h2>
        <p>Most SaaS agreements state that customers do not have a right to a physical copy of the software used.</p>
        <h2> Service Level Agreement  (SLA)</h2>
        <p>A cloud service agreement typically also includes a Service Level Agreement, or SLA. The SLA sets a minimum performance standard for a SaaS. Performance standards are generally related to service availability. In this part of the agreement, you can set your service's uptime percentage for services that are critical to business operations.</p>
        <h2>Subscription plan and model</h2>
        <p>Provisions should specify exactly what the subscription plan includes, as well as how your services will be delivered.</p>
        <h2>Term, Termination, and Renewal</h2>
        <h2>Dispute Resolution</h2>
        <p>This clause outlines the methods and procedures the two parties have at their disposal in case a dispute arises between them.</p>

    </div>
  )
}

export default ConditionOfUse