import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";


import PageNav from "./pages/PageNav";


function App() {

  
  const [pageNav, setPageNav] = useState([]);
  /*
    load the page to components mapping from
    JSON file (routes.json) to <Routes> used within react. 
    Use [pageNav] as second paramater, controls when the useEffect is run and rerun.
  */
  useEffect(() => {
    setPageNav(PageNav);
  },[pageNav]); 

  
  let dynRoutes = buildRoutes(pageNav);

  return (
    <>
     
      <Routes>
        {dynRoutes}
      </Routes>
     
    </>
  );
}

function buildRoutes(routePaths){
  var routeJSX = [];

  for( let [index, route] of routePaths.entries() ){
    routeJSX.push(<Route path={route.path} element = {route.component} key = {index}/>)
  }
  return routeJSX;
}

export default App;

