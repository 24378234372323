import React from 'react'

import "./BlankPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";


 export function SignOut () {
    return (
      <div>
        <Header footAct="true"></Header>
        <div className="AppContainer">
          <div className="AppMobile">            
            <div className="App-content">
              <p className="App-text">Sign Out</p>
            </div> 
          </div>

          <div className="AppPC"></div>
        </div>
        <Footer disable="true"></Footer>
      </div>      
    );
  }
 

export default SignOut