import Home from "./Home";
import SignOut from "./SignOut";
import Conditions from "./Conditions";
import Contact from "./Contact";
import PrivacyNotice from "./PrivacyNotice";
import CookiesNotice from "./CookiesNotice";

const PageNav = [
    {"id": 1, "type" : "home", "href": "Home", "path": "/", "lnkText" : "Home", "component" : <Home/>}, 
    {"id": 2, "type" : "signout", "href": "SignOut", "path": "/SignOut", "lnkText" : "Sign Out", "component" : <SignOut/> }, 
    {"id": 3, "type" : "notice", "href": "ContactUs", "path": "/ContactUs", "lnkText" : "Contact Us", "component" : <Contact/> }, 
    {"id": 4, "type" : "notice", "href": "CookiesNotice", "path": "/CookiesNotice", "lnkText" : "Cookies Notice", "component" : <CookiesNotice/>}, 
    {"id": 5, "type" : "notice", "href": "PrivacyNotice", "path": "/PrivacyNotice", "lnkText" : "Privacy Notice", "component" : <PrivacyNotice/> }, 
    {"id": 6, "type" : "notice", "href": "ConditionsOfUse", "path": "/ConditionsOfUse", "lnkText" : "Conditions Of Use", "component" : <Conditions/>}
];

export default PageNav;