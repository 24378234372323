import React from 'react'
import { useState} from 'react';
import {useNavigate} from 'react-router-dom';

import logo from '../assets/aztech_logo.svg';
import menu_icon from '../assets/burger_menu.svg';
import menu_icon_active from '../assets/burger_menu_activated.svg';
import menu_icon_return from '../assets/burger_menu _return.svg'

//drop down menu
import menu_right_arrow from '../assets/menu/icon_right_arrow.svg'
import menu_icon_customer from '../assets/menu/menu_icon_customer.svg'
import menu_icon_trades from '../assets/menu/menu_icon_trades.svg'
import menu_icon_business from '../assets/menu/menu_icon_business.svg'
import menu_icon_register from '../assets/menu/menu_icon_register.svg'
import menu_icon_emergency from '../assets/menu/menu_icon_emergency.svg'
import menu_icon_showcase from '../assets/menu/menu_icon_project_showcase.svg'
import menu_icon_howitworks from '../assets/menu/menu_icon_howitworks.svg'
import menu_icon_aboutus from '../assets/menu/menu_icon_about_us.svg'

const Header = ({footAct}) => {
    
    const headerMargin = 18;
    const borderBoxWidth = 3;
    const headerItemsPadding = 20;
    const headerGap = (headerMargin * 2) + (borderBoxWidth * 2) + (headerItemsPadding* 2);
    const logoHeight = 50;
 
    const [iconState, setIconState] = useState(footAct? 2 : 0);

    const [hover, setHover] = useState(false);
    const [navActive, setNavActive] = useState(false);

    const navigate = useNavigate();
    

    const headerBackdrop = {
        opacity: '100',  // 100 - display      0 - no display
        backgroundColor: 'var(--background-gradient-colour)',
       
        position: 'fixed',
        top: '0',
        width: '100vw',
        height: '110px',
        zIndex: '10', // make sure it is on top
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    };

    const headerContainer = {
        display: 'flex',
        justifyContent: 'space-between',
       /*  position: 'fixed', */
        width: `calc(100% - ${headerGap}px)`,
       
        backgroundColor: 'var(--header-grey)',
        border: `${borderBoxWidth}px solid var(--black)`,
        paddingBlock: '5px',
        margin: `${headerMargin}px`,
        borderRadius: '8px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        paddingLeft: `${headerItemsPadding}px`,
        paddingRight: `${headerItemsPadding}px`,
    };

    const headerLogo = {
        display: 'block',
        width: 'auto',
    };

    const containerBurgerBar = {
        display: 'flex',
      /*   border: `${borderBoxWidth}px solid var(--logo-green)`,  */
        alignItems: 'center',
        opacity: hover ? '0.15' : '1',
        transform: hover ? 'scale(1.2)' : null,
        borderRadius: hover ? '20%' : null,
        boxShadow: hover ? '0px 0px 30px rgba(0,0,0,0.9)' : null,       
    };

    let menuIcon = {
        opacity: iconState === 0 ? '1' : '0', 
        position: 'absolute',  
    };

    let menuIconActived = {
        position: 'absolute',
        opacity: iconState === 1 ? '1' : '0', 
    };

    let menuIconReturn = {
        position: 'relative',
        opacity: iconState === 2 ? '1' : '0',    
    };

    const handleMouseEnter = () => {
        setHover(true);
        /* console.log('Enter'); */
    };

    const handleMouseExit = () => {
        setHover(false);
        /* console.log('Exit'); */
    };

    const handleMenuClicked = () => {
        
        if( iconState === 0 ){
            // burger bar icon is showing
            setIconState(1);
            setNavActive(true);
            console.log("menu is open");
            return;

        }else if(iconState === 1){
            // menu is active
            setIconState(0);
            setNavActive(false);
            console.log("menu is closed");
            return;
        }else if(iconState === 2){
            // return is active
            setIconState(0);
            navigate('/');   // return to the home page
            console.log("return");
            return;
        }
    };

    const onKeyDown = (event) => {
        event.preventDefault();  // cancel space key when used to navigate, else browser will interpert as a scroll event
        if (event.key === 'Enter' || event.key === ' ') {
            handleMenuClicked();
          }
    };

    return (
        <header >
            <MainMenu navState = {navActive}></MainMenu>
            <div style={headerBackdrop}>
                <div style={headerContainer}>
                    <a href="#Home" >
                        <img style={headerLogo} alt="AsTech Home" src={logo} height={logoHeight}></img>
                    </a>

                     
                    <div // make the div look and behave like a button
                        style={containerBurgerBar}  
                        onClick={handleMenuClicked}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseExit}
                        role='button'
                        tabIndex='0'
                        onKeyDown={onKeyDown}
                    >
                        <img style={menuIcon} alt="Menu" src={menu_icon} width="40" height="40"></img>
                        <img style={menuIconActived} alt="Menu" src={menu_icon_active} width="40" height="40"></img> 
                        <img style={menuIconReturn} alt="Menu" src={menu_icon_return} width="40" height="40"></img> 

                    </div>
                </div>
            </div>
        </header>
    )
}

const MainMenu = ({navState}) => {

    
    const   mainMenuStyle = {
        display: navState ? '' : 'none',
        fontFamily: 'Roboto',
        border: '2px solid var(--black)',
	    borderRadius: '8px',
	    backgroundColor: 'var(--light-grey)',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: '5px',
        marginRight: '20px',
        
        position: 'fixed',  // warning fixed to header height
        top: '100px',
        right: '5px',
        zIndex: '50',
    };

   
    const menuAccounts = {
        backgroundColor: 'var(--grey-dark-1)',
        borderRadius: '8px', 
        padding: '10px 5px 15px 5px',
    };

    const menuList = {
        listStyle: 'none',
        margin: '0',
        padding: '0'
    };

    const menuAccountTitle = {
        border: '2px solid var(--black)', 
        borderRadius: '5px', 
        backgroundColor: 'var(--grey-dark)',
        color: 'var(--white)',
        marginBottom : '15px', 
        padding: '3px',
    };

    const menuAccTitleText = {
        display : 'flex',
        textTransform: 'uppercase',
        fontWeight: 'var(--weight-ext-bold)',
        letterSpacing : '3px',
        fontSize: '1.2rem',
        justifyContent: 'center',  
    };

    const menuOther = {
        padding: '10px 5px 15px 5px',
    }

    const menuOptSeparator = {
        height: '1px',
        borderTop: '2px solid var(--logo-green)',
        paddingLeft: '0px',
        paddingRight: '0px', 
        marginTop: '8px',
        marginBottom: '8px',
    };

    const menuListItem = {
        /* display: 'inline',  */
    };


    const menuListLink = {
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',   
    };

    const menuListIcon = {
       /*  display: 'flex', */
        padding: '0px',
        width:   '40px',
        height:  '40px',
        paddingRight: '5px', 
        
    };

    const menuListText = {
        display: 'flex', 
        fontSize: 'var(--fontsize-label-2)',
        color: 'var(--black-alpha-80)',
        flexGrow: '1',  // make the span section grow and take all the availiable width
        justifyContent: 'center',
    };

    const menuListArrow = {      
        padding: '0px',
        width:   '30px',
        height:  '30px',
        paddingLeft: '5px',
    };

    return (
        <div>
            <nav style={mainMenuStyle}>
                <div style={menuAccounts}>
                    <ul style={menuList}>


                        <div style={menuAccountTitle}>
                            <span style={menuAccTitleText}>Accounts</span>
                        </div>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#Customers" style={menuListLink}>
                                <img style={menuListIcon} alt="Customers Icon" src={menu_icon_customer} width="30" height="30"></img>
                                <span style={menuListText}>Customers</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                            
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#trades" style={menuListLink}>
                                <img style={menuListIcon} alt="Trades Icon" src= {menu_icon_trades} width="30" height="30"></img>
                                <span style={menuListText}>Trades</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#businesses" style={menuListLink}>
                                <img style={menuListIcon} alt="Business Icon" src={menu_icon_business} width="30" height="30"></img>
                                <span style={menuListText}>Business</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                    </ul>
                </div>

                <div style={menuOther}>
                    <ul style={menuList}>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#register" style={menuListLink}>
                                <img style={menuListIcon} alt="Register Icon" src={menu_icon_register} width="30" height="30"></img>
                                <span style={menuListText}>Why Register</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#emergency" style={menuListLink}>
                                <img style={menuListIcon} alt="Emergency Icon" src={menu_icon_emergency} width="30" height="30"></img>
                                <span style={menuListText}>Emergency</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#showcase" style={menuListLink}>
                                <img style={menuListIcon} alt="Project Showcase Icon" src={menu_icon_showcase} width="30" height="30"></img>
                                <span style={menuListText}>Project Showcase</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#working" style={menuListLink}>
                                <img style={menuListIcon} alt="How It Works Icon" src={menu_icon_howitworks} width="30" height="30"></img>
                                <span style={menuListText}>How it Works</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>

                        <li style={menuListItem}>
                            <a href="#about" style={menuListLink}>
                                <img style={menuListIcon} alt="About Us Icon" src={menu_icon_aboutus} width="30" height="30"></img>
                                <span style={menuListText}>About Us</span>
                                <img style={menuListArrow} alt="Right Arrow" src={menu_right_arrow} width="25" height="25"></img>
                            </a>
                        </li>

                        <div style={menuOptSeparator}></div>
                    </ul>
                </div>
            </nav>
        </div>
    )
};


export default Header;