import React from 'react';
import "./Notices.css";
const PrivacyPolicy= () => {
    



  return (
    <div className="page_bg">
        <h1 className="h1_Style">Privacy Policy</h1>
        <p>Arcs and Sparks Technology digital services <span>(A&STech)</span> is a company 
            dedicated to providing internet based digital services 
            to Trades people and their customers.
        </p>
        <h2 className="h2_Style">Our commitment to you</h2>
        <p>
            As a company we are committed to protecting and respecting the privacy of your personal data. 
            This privacy notice explains how your data is collected, used, transferred and disclosed by A&STech. 
            It applies to data collected when you use our websites, iOS and android applications, 
            when you interact with us through social media, email, or phone. 
        </p>
        <p>
        <span>A&STech</span> will hold and process all personal data we hold about you in accordance with UK General 
            Data Protection Regulations (GDPR) and the Data protection Act 2018.
        </p>
        <p>The following summarises :</p>

        <ul>
            <li>How we use personal data</li>
            <li>What personal data we need</li>
            <li>Why we need it</li>
            <li>How we use it</li>
            <li>Who we might share it with</li>
            <li>How long we will keep it for</li>
        </ul>
        <h2 className="h2_Style">What is personal data ?</h2>
        <p>
            Personal data is any information relating directly or indirectly to a Trades person or customer. 
            This information includes things  such as a name, address, contact information, identification numbers, 
            location data and online identifier.
        </p>
        <h2 className="h2_Style">How we keep your data safe and secure</h2>
        <p>We have appropriate organisational safeguards and security measures in place to protect your data from being accidentally lost, 
            used or accessed in an unauthorised way, altered or disclosed.
            The communication between your browser and our website uses a secure encrypted connection wherever your personal data is involved.
            We require any third party who is contracted to process your personal data on our behalf to have security measures in place to protect your 
            data and to treat such data in accordance with the law. In the unfortunate event of a personal data breach, 
            we will notify you and any applicable regulator when we are legally required to do so.
        </p>
        <h2 className="h2_Style">What personal data do we process ?</h2>
        <p>The following groups of personal data are collected:</p>
        <ul>
            <li><span>Identity Data</span> includes information such as: first name, last name, title, date of birth (optional), occupation, personal description, photo and gender.</li>
            <li><span>Contact Data </span> includes information such as: email address, billing address, delivery address, location, country, telephone number, loyalty programme membership number, and social media id (if you log in by social media).</li>
            <li><span>Financial Data </span> includes information such as: payment card details and bank account.</li>
            <li><span>Transaction Data </span> includes information such as: 
                <ul>
                    <li>payments to and from you</li>
                    <li>details of services you have obtained from us.</li>
                    <li>correspondence or communications with you in respect of your work</li>
                </ul>
            </li>
            <li><span>Technical Data </span> includes information such as: details of the device(s) you use to access our services, your internet protocol (IP) address, login data, your username and password, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform.</li>
            <li><span>Profile Data </span> includes information such as: orders made by you and feedback responses.</li>
            <li><span>Usage Data </span> includes information such as: how and when you use our website/app, how you moved around it, what you searched for; website/app performance statistics, traffic, location, weblogs and other communication data. </li>
            <li><span>Marketing and Communications Data </span> We do not sell or release any personnel data or data as described above, to any third party. </li>
        </ul>
        <h2 className="h2_Style">Why do we process personal data ?</h2>
        <h3>The legal basis for processing your personal data</h3>
        <p>We will only collect and process your personal data where we have a legal basis to do so. As a data controller, the legal basis for our collection and use of your personal data varies depending on the manner and purpose for which we collected it.</p>
        <p>We will only collect personal data from you when:</p>
        <ul>
            <li>we have your consent to do so, or</li>
            <li>we need your personal data to perform a contract with you. For example, to process a payment from you, complete your works order or provide customer support connected with a works order, or</li>
            <li>the processing is in our legitimate interests and not overridden by your rights, or</li>
            <li>we have a legal obligation to collect or disclose personal data from you.</li>
        </ul>
        <h2 className="h2_Style">How do we process your personal data ?</h2>
        <p>Your personal data is used by <span>A&STech</span> to support a range of different activities. These are listed in the table below together with the types of data used and the legal bases we rely on when processing them, including where appropriate, our legitimate interests. Please be aware that we may process your personal data using more than one lawful basis, depending on the specific activity involved. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
        <table className='tablePrivacy'>
            <tr>
                <td>To create an account and register you as a new customer (either directly or via social media).</td>
                <td>Identity, Contact</td>
                <td>Consent</td>
            </tr>
            <tr>
                <td>To process your works order including: recording your order details; keeping you informed about the order status; process payments and refunds, collect money owed to us;
To protect our customers and digital service (website) from fraud and theft, which involves automated decision making to assist such fraud prevention and detection.</td>
                <td>Identity, Contact, Financial Transaction</td>
                <td>Performance of a contract with you Necessary for our legitimate interests (e.g. to recover debts due to us) For automated decision making we consider that fraud detection and prevention is in our legitimate interests to ensure that fraudulent transactors are unable to benefit from our services and in the legitimate interest of the public as whole due to the impact of fraud on the consumer market; we also consider it a necessary element of entering into a contract with you that we are able to verify your identity and prevent fraud.</td>
            </tr>
            <tr>
                <td>To manage our relationship with you, including:  notifying you about changes to our services, terms and conditions or privacy notice; asking you to leave a review or take a survey.</td>
                <td> Identity, Contact, Profile, Marketing and Communications</td>
                <td>Consent Performance of a contract with you</td>
            </tr>
            <tr>
                <td>To administer, protect and improve our business and our website/app, including: troubleshooting, data analysis, testing, system maintenance, support, data analysis, reporting and hosting of data;</td>
                <td>Identity, Contact, Profile, Technical, Transaction, Marketing and Communications</td>
                <td>Consent</td>
            </tr>
            <tr>
                <td>To use data analytics to improve: our website, services, customer relationships and experiences</td>
                <td>Technical Usage</td>
                <td>Consent Legitimate interests and to improve our websites, products, services, marketing, customer relationships and experiences.</td>
            </tr>
            <tr>
                <td>To inform or remind you by email of any task carried out via our website which remains uncompleted, such as incomplete works orders </td>
                <td>Identity, Contact, Usage</td>
                <td>Consent</td>
            </tr>
            <tr>
                <td>To protect all our users and customers, from fraud and theft</td>
                <td>Identity, Contact, Profile</td>
                <td>Necessary for our legitimate interests (to detect and prevent fraud)</td>
            </tr>
        </table>
        <h2 className="h2_Style">Cookies</h2>
        <p>Our website uses cookies to distinguish you from other users of our website and to keep track of your visits. They help us to provide you with the very best experience when you browse our website and to make improvements to our website.</p>
        <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly.</p>
        <p>For detailed information on the use of cookies on our website, please refer to our<span>Cookie Policy.</span></p>
        <h2 className="h2_Style">How long will we keep your personal data?</h2>
        <p>We will keep your personal data for no longer than is necessary for the purpose(s) it was provided for and to meet our legal obligations. Further details of the periods for which we retain data are available on request.</p>
        <h2 className="h2_Style">Your data rights</h2>
        <p>You have several rights under the data privacy legislation. This includes, under certain circumstances, the right to:</p>
            <ul>
                <li>request access to your personal data</li>
                <li>request correction of your personal data</li>
                <li>request erasure of your personal data</li>
                <li>request restriction of processing of your personal data</li>
                <li>request the transfer of your personal data</li>
                <li>object to processing of your personal data</li>
                <li>Request human intervention for automated decision making Brief details of each of these rights are set out below. If you wish to exercise any of these rights, please email us at DPO@arcs-and-sparks.tech</li>
            </ul>
        <h3>Request access to your personal data</h3>
        <p>You have the right to obtain a copy of the personal data we hold about you and certain information relating to our processing of your personal data.</p>
        <h3>Request correction of your personal data</h3>
        <p>You are entitled to have your personal data corrected if it is inaccurate or incomplete. You can update your personal data at any time by logging into your  account and updating your details directly, or by emailing us at DPO@arcs-and-sparks.tech</p>
        <h3>Request erasure of your personal data</h3>
        <p>This enables you to request that Arcs and Sparks Technology delete your personal data, where there is no good reason for us continuing to process it. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
        <h3>Request restriction of processing of your personal data</h3>
        <p>You have a right to ask Arcs and Sparks Technology to suspend the processing of your personal data in certain scenarios, for example if you want us to establish the accuracy of the data, or you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. Where processing is restricted, we are allowed to retain sufficient information about you to ensure that the restriction is respected in future.</p>
        <h3>Request the transfer of your personal data</h3>
        <p>You have the right to obtain a digital copy of your personal data or request the transfer of your personal data to another company. Please note though that this right only applies to automated data which you initially provided consent for us to use or where we used the data to perform a contract with you.</p>
        <h3>Object to processing of your personal data</h3>
        <p>You have the right to object to the processing of your personal data where we believe we have a legitimate interest in processing it (as explained above). You also have the right to object to our processing of your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your data which override your rights and freedoms.</p>
        <h3>Request human intervention for automated decision making and profiling</h3>
        <p>You have the right to request human intervention where we are carrying out automated decision making when processing your personal data. This form of processing is permitted where it is necessary as part of our contract with you, providing that appropriate safeguards are in place or your explicit consent has been obtained.</p>
        <p>We will try to respond to all legitimate requests within one month. Occasionally, it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. We may need to request specific information from you to help us confirm your identity and ensure your right to exercise any of the above rights. This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it.</p>
        <h3>Right to lodge a complaint</h3>
        <p>If you have any concerns or complaints regarding the way in which we process your data, please email us directly at  DPO@arcs-and-sparks.tech. You also have the right to make a complaint to the ICO (the data protection regulator in the UK). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please do contact us in the first instance.</p>
        <h3>Changes to this privacy notice</h3>
        <p>From time to time we may change this privacy notice. If there are any significant changes we will post updates on our website, applications or let you know by email.</p>

    </div>
  )
}

export default PrivacyPolicy