import React from 'react';
import FooterLink from "./FooterLink";
import PageNav from '../pages/PageNav';

const Footer = (props) => {

  const today = new Date();

  const footerStyle = {
    fontFamily: 'Roboto',
    flexShrink: '0',
    minWidth: '100vw',
  };

  const footerBackground = {
    borderTop: '2px solid var(--black)',
    backgroundColor: 'var(--footer)',
    color: 'var(--white)',
    padding: '10px',
  }

  const footerSignOut = {
    paddingTop: '3px',
    paddingBottom: '3px',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  };


  const footerNotices = {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1.3em',
    textAlign: 'center',
    padding: '20px 0 15px 0',
    fontSize: '0.8rem',
  }

  const footerCopyRight = {
    borderTop: '2px solid var(--green-alpha50)',
    textAlign: 'center',
    paddingTop: '5px',
    fontSize: '0.55rem',
  };

  const copyRight = {
    color: 'var(--yellow-shade)',
  };

  
  const signOut = buildSignOut(PageNav, props);
  const linkNotices = buildNotices(PageNav, props);

  return (
    <footer style={footerStyle}>
      <div style={footerBackground}>
        <div style={footerSignOut}>         
         {signOut}
        </div>
        <div style={footerNotices}>
          {linkNotices}
        </div>
        <div style={footerCopyRight}>
          <span>Copyright <span style={copyRight}>&copy;{today.getFullYear()}</span> Arcs and Sparks Technology Ltd.</span>
        </div>

      </div>
    </footer>
  )
}

function buildSignOut(routePaths, props){
  var buildSO = [];

  for( let [index, route] of routePaths.entries() ){
    if( route.type === 'signout')
      buildSO.push(<FooterLink disable={props.disable} linkName={route.lnkText} page = {route.href} key = {index}/>)
  }
  return buildSO;
}

function buildNotices(routePaths, props){
  var buildNotice = [];

  for( let [index, route] of routePaths.entries() ){
    if( route.type === 'notice')
    buildNotice.push(<FooterLink disable={props.disable} linkName={route.lnkText} page = {route.href} key = {index}/>)
  }
  return buildNotice;
}

export default Footer